import { Stack, Typography } from '@mui/material'
import NextLink from 'next/link'
import { useTranslation } from 'react-i18next'
import { Link } from 'settle-ui/components/Link/Link'
import { ExternalAppLayout } from 'routes/components/ExternalAppLayout/ExternalAppLayout'

const Page404 = () => {
  const { t } = useTranslation()

  return (
    <ExternalAppLayout>
      <Stack height="100%" spacing={4} alignItems="center" justifyContent="center">
        <Stack alignItems="center" gap={4}>
          <Typography variant="h3" color="primary">
            {t('page404Title')}
          </Typography>
          <Link href="/" color="secondary" component={NextLink}>
            {t('openMyDashboard')}
          </Link>
        </Stack>
      </Stack>
    </ExternalAppLayout>
  )
}

// eslint-disable-next-line import/no-default-export
export default Page404
